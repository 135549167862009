<template>
  <div>
    <Card>
      <template #header>İhale Bedelleri</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div>
          <button class="btn btn-primary" @click="addBedel = true">
            <i class="fas fa-plus"></i>Yeni İhale
          </button>
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="İhale Bedelleri Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0" @click="updateValue(item)">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <!-- Create and Actions Popups -->
    <b-modal v-model="addBedel" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i>Yeni İhale Bedeli</h4>
      </template>
      <div class="row">
        <div class="col-md-16 col-6">
          <div class="form-group">
            <label for="" style="font-size: 0.9rem !important"
              >Güncelleme Tarihi</label
            >
            <input type="date" class="form-control" v-model="date_ofupdate" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="ihaleBedeli" style="font-size: 0.9rem !important"
              >İhale Bedeli</label
            >
            <input
              type="text"
              class="form-control"
              v-model="tender_price"
              id="ihaleBedeli"
              name="İhale Bedeli"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="explanation" style="font-size: 0.9rem !important"
              >Açıklama</label
            >
            <textarea
              name="explanation"
              id="explanation"
              rows="1"
              class="form-control"
              v-model="explanation"
            ></textarea>
          </div>
        </div>
        <div class="col-4 form-group">
          <label for="teminatOrani" style="font-size: 0.9rem !important"
            >Teminat Oranı (%)</label
          >
          <input
            type="text"
            value="0"
            class="form-control"
            id="teminatOrani"
            name="teminatOrani"
            v-model="coverage_rate"
          />
        </div>
        <div class="col-4 form-group">
          <label for="ihaleSuresi" style="font-size: 0.9rem !important"
            >İhale Süresi (Gün)</label
          >
          <input
            type="text"
            value="0"
            class="form-control"
            id="ihaleSuresi"
            name="ihaleSuresi"
            v-model="tender_duration"
          />
        </div>
        <div class="col-4 form-group">
          <label for="hizmetBedel2" style="font-size: 0.9rem !important"
            >Hizmet Bedeli (%)</label
          >
          <input
            type="text"
            value="0"
            class="form-control"
            id="hizmetBedel2"
            name="hizmetBedel2"
            v-model="service_fee"
          />
        </div>
        <div class="col-4 form-group">
          <label for="ekHizmetBedel1" style="font-size: 0.9rem !important"
            >Ek Hizmet Bedeli (%)</label
          >
          <input
            type="text"
            value="0"
            class="form-control"
            id="ekHizmetBedel1"
            name="ekHizmetBedel1"
            v-model="additional_service"
          />
        </div>
        <div class="col-4 form-group">
          <label for="hizmetBedeliTL" style="font-size: 0.9rem !important"
            >Hizmet Bedeli (TL)</label
          >
          <input
            type="text"
            value="0"
            class="form-control"
            id="hizmetBedeliTL"
            name="hizmetBedeliTL"
            v-model="service_fee"
          />
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addBedel = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="save" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "systemUsers",
  data() {
    return {
      updateModal: false,
      addBedel: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Güncelleme Tarihi", value: "date_modified" },
        { text: "İhale Tutarı", value: "tender_price" },
        { text: "Teminat Oranı", value: "coverage_rate" },
        { text: "İhale SÜresi(GÜN)", value: "tender_duration" },
        { text: "Hizmet Bedeli", value: "service_fee" },
        { text: "Açıklama", value: "explanation" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",

      date_ofupdate: "",
      tender_price: " ",
      explanation: "",
      coverage_rate: "",
      tender_duration: "",
      service_fee: "",
      additional_service: "",
      service_fee: "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    updateValue(item) {
      (this.date_ofupdate = moment(this.date_ofupdate).format("DD/MM/YYYY")),
        (this.tender_price = item.tender_price);
      this.explanation = item.explanation;
      this.coverage_rate = item.coverage_rate;
      this.tender_duration = item.tender_duration;
      this.service_fee = item.service_fee;
      this.additional_service = item.additional_service;
      this.service_fee = item.service_fee;
      this.advert_id = item._id.$oid;
      this.addBedel = true;
      this.updateModal = true;
    },
    save() {
      let data = {
        date_ofupdate: moment(this.date_ofupdate).format("DD/MM/YYYY"),
        tender_price: this.tender_price,
        explanation: this.explanation,
        coverage_rate: this.coverage_rate,
        tender_duration: this.tender_duration,
        service_fee: this.service_fee,
        additional_service: this.additional_service,
        // service_fee_percent: this.service_fee_percent,
        user_token: this.myUser,
        advert_id: this.$route.params.id,
      };
      if (!this.updateModal) {
        this.$ApiService
          .post("advert/tenderfees", data)
          .then((res) => {
            this.$generateNotification(this, "success", "İhale Eklenmiştir."),
              this.getList();
            this.addBedel = false;
          })
          .catch((err) => {
            this.$generateNotification(this, "error", "İhale Eklenemedi.");
          });
      } else {
        data["advert_id"] = this.advert_id;
        const formData = new FormData();
        for (const key in data) {
          formData.append(key, data[key]);
        }
        this.$ApiService
          .put("advert/tenderfees", formData)
          .then((res) => {
            this.$generateNotification(
              this,
              "success",
              "İhale Bedeli Başarıyla Güncellendi."
            );
            this.getList();
            this.addBedel = false;
          })
          .catch((err) => {
            this.$generateNotification(
              this,
              "error",
              "İhale Bedeli Güncellenemedi."
            );
          });
      }
    },
    getList() {
      this.loading = true;
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `advert/tenderfees?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&advert_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          if (res.status.code == 200) {
            this.items = res.response.data;
            this.totalItems = res.response.data.length;

            this.pagination = {
              current_page: 1,
              total_pages: Math.ceil(res.response.data.length / 10),
              total_items: this.totalItems,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
